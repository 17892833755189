import type { UseQueryOptions } from '@tanstack/vue-query';
import { unref, type MaybeRef, ref } from 'vue';

import type { ApiError } from '@/api';
import { getAll, type Geolocation, type GeolocatableType } from '@/api/geolocation';
import { geolocationsKey } from '@/api/queryKeys';
import useReactiveQuery from '@/composables/useReactiveQuery';

type FetchGeolocationsComposableArgs = {
  geolocatableIds: MaybeRef<number[]>;
  geolocatableType: MaybeRef<GeolocatableType>;
} & UseQueryOptions<Geolocation[], ApiError>

function useFetchGeolocations({ geolocatableIds, geolocatableType, ...options }: FetchGeolocationsComposableArgs) {
  return useReactiveQuery({
    ...options,
    queryKey: geolocationsKey({
      geolocatableType: ref(geolocatableType), geolocatableIds: ref(geolocatableIds),
    },
    ),
    queryFn: () => getAll(
      unref(geolocatableIds), unref(geolocatableType),
    ).then((response) => response.data.geolocations),
  });
}

export { useFetchGeolocations };
