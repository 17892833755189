<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { ref, watch, onMounted, onUnmounted } from 'vue';

import useFilteredAttrs from '@/composables/useFilteredAttrs';

interface Props {
  open: boolean;
  unmount?: boolean;
  name?: string;
}

interface Emits {
  (e: 'close'): void;
}

defineEmits<Emits>();

const MIN_OVER_TRANSITION_DURATION = 800;

const props = withDefaults(defineProps<Props>(), {
  unmount: false,
  name: undefined,
});

const open = ref(props.open);

watch(
  () => props.open,
  (newVal) => {
    open.value = newVal;

    setTimeout(() => {
      const vueAppElement = document.getElementById('vue-app');
      if (vueAppElement) {
        vueAppElement.removeAttribute('inert');
      }

      if (newVal) {
        document.documentElement.classList.add('allow-scroll');
      } else {
        document.documentElement.classList.remove('allow-scroll');
      }
    }, MIN_OVER_TRANSITION_DURATION);
  },
);

const filteredAttrs = useFilteredAttrs({ excluded: ['unmount', 'open'] });

const DEFAULT_SLIDE_OVER_WIDTH = 700;
const MIN_SLIDE_OVER_WIDTH = 300;
let maxSlideOverWidth = window.innerWidth * 0.8; // eslint-disable-line no-magic-numbers
const slideOverWidth = ref(DEFAULT_SLIDE_OVER_WIDTH);
let isResizing = false;
let startX = 0;
let startWidth = 0;

function onWindowResize() {
  maxSlideOverWidth = window.innerWidth * 0.8; // eslint-disable-line no-magic-numbers

  if (slideOverWidth.value > maxSlideOverWidth) {
    slideOverWidth.value = maxSlideOverWidth;
  }
}

onMounted(() => {
  const savedWidth = sessionStorage.getItem(`slide-over-width-${props.name || 'default'}`);
  if (savedWidth) {
    slideOverWidth.value = parseInt(savedWidth, 10);
  }

  window.addEventListener('resize', onWindowResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', onWindowResize);
});

watch(slideOverWidth, (newWidth) => {
  sessionStorage.setItem(`slide-over-width-${props.name || 'default'}`, newWidth.toString());
});

function onMouseMove(event: MouseEvent) {
  if (!isResizing) return;

  const dx = event.clientX - startX;
  const newWidth = startWidth - dx;

  slideOverWidth.value = Math.max(MIN_SLIDE_OVER_WIDTH, Math.min(newWidth, maxSlideOverWidth));
}

function onMouseUp() {
  isResizing = false;
  document.removeEventListener('mousemove', onMouseMove);
  document.removeEventListener('mouseup', onMouseUp);
}

function onMouseDown(event: MouseEvent) {
  isResizing = true;
  startX = event.clientX;
  startWidth = slideOverWidth.value;

  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('mouseup', onMouseUp);
}
</script>

<template>
  <TransitionRoot
    as="template"
    :show="open"
    :unmount="unmount"
    v-bind="filteredAttrs"
  >
    <Dialog
      as="div"
      class="relative z-30"
      :unmount="unmount"
    >
      <div class="pointer-events-none fixed inset-y-0 right-0 z-50 flex max-w-full">
        <TransitionChild
          :unmount="unmount"
          as="template"
          enter="transform transition ease-in-out duration-500 sm:duration-700"
          enter-from="translate-x-full"
          enter-to="translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-700"
          leave-from="translate-x-0"
          leave-to="translate-x-full"
        >
          <DialogPanel
            class="pointer-events-auto relative h-full flex-none"
            :style="{ width: slideOverWidth + 'px' }"
          >
            <div
              class="absolute left-0 top-0 z-50 h-full w-1 cursor-col-resize"
              @mousedown="onMouseDown"
            />
            <div class="relative size-full max-h-screen grow rounded-md bg-white px-4 py-12 shadow-xl">
              <button
                type="button"
                class="absolute left-2 top-2 text-gray-400 hover:text-gray-500"
                @click="$emit('close')"
              >
                <span class="sr-only">Close panel</span>
                <XMarkIcon
                  class="size-6"
                  aria-hidden="true"
                />
              </button>
              <div class="h-full overflow-y-scroll">
                <slot />
              </div>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style>
.allow-scroll {
  overflow: auto !important;
}
</style>

