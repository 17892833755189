import type { UseQueryOptions } from '@tanstack/vue-query';
import { unref, type MaybeRef } from 'vue';

import type { ApiError } from '@/api';
import { index, type KycDocument } from '@/api/kycDocument';
import { documentableKycDocuments } from '@/api/queryKeys';
import useReactiveQuery from '@/composables/useReactiveQuery';

type FetchDocumentsComposableArgs = Omit<UseQueryOptions<KycDocument[], ApiError>, 'queryKey' | 'queryFn'> & {
  documentableId: MaybeRef<number>,
  documentableType: MaybeRef<string>,
}

function useFetchKycDocuments({ documentableId, documentableType, ...options }: FetchDocumentsComposableArgs) {
  return useReactiveQuery({
    queryKey: documentableKycDocuments({ documentableId, documentableType }),
    queryFn: () => index({
      documentableId: unref(documentableId), documentableType: unref(documentableType),
    }).then((response) => response.data.kycDocuments),
    ...options,
  });
}

export { useFetchKycDocuments };
