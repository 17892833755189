<script setup lang="ts">
import { computed } from 'vue';

import type { GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';
import BaseBadge from '@/components/base-badge.vue';
import { groupMemberLoanApplicationBadgeColor } from '@/utils/states';

interface Props {
  groupMemberLoanApplication: GroupMemberLoanApplication;
  aasmStateToShow?: GroupMemberLoanApplication['aasmState'];
  selected: boolean;
  ping?: boolean;
}

const props = defineProps<Props>();

const aasmState = computed(() => props.aasmStateToShow || props.groupMemberLoanApplication.aasmState);
</script>

<template>
  <button
    class="flex flex-col gap-y-1.5 p-2 md:-mx-2 md:py-4"
    :class="selected ? 'bg-primary-600 text-white' : 'hover:bg-gray-100'"
  >
    <div class="flex items-center gap-x-1">
      <div
        v-if="ping"
        class="relative flex size-2 items-center"
      >
        <div class="absolute inline-flex size-2 animate-ping rounded-full bg-primary-400 opacity-75" />
        <div class="relative inline-flex size-2 rounded-full bg-primary-500" />
      </div>
      <p class="w-36 text-left text-sm font-semibold md:w-auto">
        {{ groupMemberLoanApplication.groupMember.user.fullName }}
      </p>
    </div>
    <div class="flex flex-col-reverse items-center gap-x-2 gap-y-1 md:flex-row">
      <p
        class="text-left text-xs"
        :class="selected ? 'text-gray-50' : 'text-gray-700'"
      >
        ID: {{ groupMemberLoanApplication.publicId }}
      </p>
      <slot
        v-if="groupMemberLoanApplication.groupMember.active && groupMemberLoanApplication.aasmState !== 'rejected'"
        name="badge"
      >
        <base-badge
          :color="groupMemberLoanApplicationBadgeColor(aasmState)"
          :label="$t(`groupMemberLoanApplication.aasmState.${aasmState}`)"
          size="xs"
        />
      </slot>
      <base-badge
        v-else-if="groupMemberLoanApplication.aasmState === 'rejected'"
        :color="groupMemberLoanApplicationBadgeColor('rejected')"
        :label="$t('groupMemberLoanApplication.aasmState.rejected')"
        size="xs"
      />
      <base-badge
        v-else
        color="red"
        label="Inactivo"
        size="xs"
      />
    </div>
    <p
      class="text-left text-xs"
      :class="{ 'text-gray-50': selected }"
    >
      {{ groupMemberLoanApplication.groupMember.humanRole }}
    </p>
  </button>
</template>
