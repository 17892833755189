import { useQueryClient, type MutationOptions } from '@tanstack/vue-query';
import { unref, type MaybeRef } from 'vue';

import type { ApiError } from '@/api';
import { update, type KycDocument, type BaseUpdatePayload } from '@/api/kycDocument';
import { documentableKycDocuments } from '@/api/queryKeys';
import useReactiveMutation from '@/composables/useReactiveMutation';
import useUiStore from '@/stores/ui';

interface MutationFnArgs<TPayload> {
  id: number;
  body: TPayload & {
    kycDocumentType: KycDocument['kind'];
  };
}

type UseUpdateKycDocumentComposableArgs<TPayload> = {
  documentableId: MaybeRef<number>
  documentableType: MaybeRef<string>
} & Omit<MutationOptions<KycDocument, ApiError, MutationFnArgs<TPayload>>, 'mutationFn' | 'mutationKey'>

function useUpdateKycDocument<TPayload = BaseUpdatePayload>(
  {
    documentableId,
    documentableType,
    ...options
  }: UseUpdateKycDocumentComposableArgs<TPayload>,
) {
  const queryClient = useQueryClient();
  const uiStore = useUiStore();

  return useReactiveMutation({
    ...options,
    mutationFn: ({ id, body }) => update<TPayload>(unref(id), body).then((response) => response.data.kycDocument),
    onSuccess: (...args) => {
      options.onSuccess?.(...args);
      queryClient.invalidateQueries({ queryKey: documentableKycDocuments({ documentableId, documentableType }) });
    },
    onError: () => {
      uiStore.toast({
        message: 'Ocurrió un error al actualizar el documento',
        type: 'error',
        position: 'top',
      });
    },
  });
}

export { useUpdateKycDocument };
