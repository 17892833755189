<script setup lang="ts">
import { GoogleMap } from 'vue3-google-map';

interface Center {
  lat: number;
  lng: number;
}

interface Props {
  center: Center;
  zoom?: number;
  mapId: string;
}

withDefaults(defineProps<Props>(), { zoom: 12 });

const GOOGLE_MAPS_API_KEY = window.env.GOOGLE_MAPS_API_KEY;
</script>

<template>
  <google-map
    :api-key="GOOGLE_MAPS_API_KEY"
    class="size-full"
    :map-id="mapId"
    :center="center"
    :zoom="zoom"
  >
    <slot />
  </google-map>
</template>
