import type { UseQueryOptions } from '@tanstack/vue-query';
import { unref, type MaybeRef } from 'vue';

import type { ApiError } from '@/api';
import { getConversation, type WhatsappConversation } from '@/api/crm/whatsapp/conversation';
import { whatsappConversationKey } from '@/api/crm/whatsapp/conversation/queryKeys';
import useReactiveQuery from '@/composables/useReactiveQuery';

type FetchConversationComposableArgs = UseQueryOptions<WhatsappConversation, ApiError> & {
  recipientPhone: MaybeRef<string>;
  account?: MaybeRef<string>;
};

function useFetchConversation({ recipientPhone, account, ...options }: FetchConversationComposableArgs) {
  return useReactiveQuery({
    queryKey: whatsappConversationKey({ recipientPhone, account }),
    queryFn: () => getConversation({ recipientPhone: unref(recipientPhone), account: unref(account) })
      .then((response) => response.data.whatsappConversation),
    ...options,

  });
}

export { useFetchConversation };
