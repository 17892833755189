import type { Ref } from 'vue';

import type { GeolocatableType } from '@/api/geolocation';

interface GeolocationsKeyParams {
  geolocatableIds: Ref<number[]>;
  geolocatableType: Ref<GeolocatableType>;
}

const GEOLOCATIONS_KEY = ['geolocations'];

function geolocationsKey(params: GeolocationsKeyParams) {
  return [...GEOLOCATIONS_KEY, params];
}

export { geolocationsKey };
