import { api } from '@/api';
import type { GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';

function rejectKyc(groupMemberLoanApplicationId: number) {
  return api.post<{ groupMemberLoanApplication: GroupMemberLoanApplication }>(
    `/api/internal/group_member_loan_applications/${groupMemberLoanApplicationId}/kyc_rejections`,
  );
}

export { rejectKyc };
