<script setup lang="ts">
import { computed } from 'vue';

import type { ConsolidatedLoan, GroupLoanApplication } from '@/api/groupLoanApplication';
import { toCurrency, formatDate } from '@/utils/filters';

interface Props {
  groupLoanApplication: GroupLoanApplication;
  consolidatedLoans: ConsolidatedLoan[];
}

const props = defineProps<Props>();

const groupMembers = computed(() => props.groupLoanApplication.memberLoanApplications.map(
  groupMemberLoanApplication => groupMemberLoanApplication.groupMember,
));

function findGroupMember(id?: number) {
  return groupMembers.value.find(member => member.id === id);
}

function findGroupMemberFromGroupMemberLoanApplicationId(id: number) {
  const groupMemberLoanApplication = props.groupLoanApplication.memberLoanApplications.find(mla => mla.id === id);

  return findGroupMember(groupMemberLoanApplication?.groupMemberId);
}

function formatMemberNames(ids: number[]) {
  return ids.map(id => findGroupMemberFromGroupMemberLoanApplicationId(id)?.user?.fullName).join(', ');
}
</script>

<template>
  <div class="flex flex-col gap-y-4 rounded-lg bg-gray-100">
    <div
      v-for="consolidatedLoan in consolidatedLoans"
      :key="consolidatedLoan.groupMemberLoanApplicationIds.join('-')"
      class="flex flex-col gap-y-4 rounded-lg bg-gray-100 p-6"
    >
      <div class="flex flex-col justify-between gap-y-5 text-sm md:flex-row">
        <div class="flex flex-col gap-y-1">
          <p class="font-semibold">
            {{ formatMemberNames(consolidatedLoan.groupMemberLoanApplicationIds) }}
          </p>
          <p>Ciclos: {{ consolidatedLoan.loans.length }}</p>
          <p>
            Fecha de apertura más reciente: {{ formatDate(consolidatedLoan.mostRecentLoanStartDate, 'dd-MM-yyyy') }}
          </p>
          <p>Peor atraso: {{ consolidatedLoan.worstDelinquencyStrike }}</p>
        </div>
        <div
          v-if="consolidatedLoan.mostRecentDelinquentBalanceAmount > 0"
          class="flex flex-col gap-y-1"
        >
          <p class="font-bold text-red-600">
            Saldo vencido: {{ toCurrency(consolidatedLoan.mostRecentDelinquentBalanceAmount) }}
          </p>
          <p>
            Número de pagos vencidos: {{ consolidatedLoan.mostRecentDelinquentPaymentsCount }}
          </p>
          <p>
            Fecha de saldo vencido más reciente: {{ consolidatedLoan.mostRecentDelinquentBalanceDate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

