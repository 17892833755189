import { toRef, type MaybeRef } from 'vue';

const KYC_DOCUMENTS_BASE_KEY = ['kycDocuments'];

function documentableKycDocuments(
  { documentableId, documentableType }: { documentableId: MaybeRef<number>; documentableType: MaybeRef<string> },
) {
  return [
    ...KYC_DOCUMENTS_BASE_KEY,
    { documentableId: toRef(documentableId), documentableType: toRef(documentableType) },
  ];
}

export { KYC_DOCUMENTS_BASE_KEY, documentableKycDocuments };
