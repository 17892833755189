<script setup lang="ts">
import { ref } from 'vue';

import CsrfHelper from '@/components/csrf-helper.vue';
import VForm from '@/components/v-form.vue';
import useFilteredAttrs from '@/composables/useFilteredAttrs';

defineProps({
  validationSchema: { type: Object, default: () => ({}) },
  method: { type: String, default: 'post' },
});

// @TODO make a component that provides a isSubmitting
// slot that doesn't changes back to false after validation
const filteredAttrs = useFilteredAttrs({ excluded: ['method', 'ref'] });
const innerFormRef = ref<HTMLFormElement | null>(null);
const vFormRef = ref(null);

defineExpose({ innerFormRef, vFormRef });
</script>

<template>
  <v-form
    v-slot="{ submitForm, isSubmitting, ...rest }"
    ref="vFormRef"
    :validation-schema="validationSchema"
    as="div"
  >
    <form
      ref="innerFormRef"
      v-bind="filteredAttrs"
      method="post"
      @submit="submitForm"
    >
      <csrf-helper />
      <input
        type="hidden"
        name="_method"
        :value="method"
      >
      <slot
        :is-submitting="isSubmitting"
        v-bind="rest"
      />
    </form>
  </v-form>
</template>
