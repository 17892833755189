import { type MutationOptions } from '@tanstack/vue-query';
import { toRef, unref, type MaybeRef } from 'vue';

import type { ApiError } from '@/api';
import { rejectKyc } from '@/api/groupMemberLoanApplication/kycRejection';
import { groupMemberLoanApplicationKey } from '@/api/queryKeys';
import useReactiveMutation from '@/composables/useReactiveMutation';

type RejectKycReturn = Awaited<ReturnType<typeof rejectKyc>>['data']['groupMemberLoanApplication'];
type RejectKycMutationArgs = MutationOptions<RejectKycReturn, ApiError> & {
  groupMemberLoanApplicationId: MaybeRef<number>;
};

function useRejectKycMutation({ groupMemberLoanApplicationId, ...options }: RejectKycMutationArgs) {
  return useReactiveMutation({
    ...options,
    mutationKey: groupMemberLoanApplicationKey(toRef(groupMemberLoanApplicationId)),
    mutationFn: () => (
      rejectKyc(unref(groupMemberLoanApplicationId)).then(response => response.data.groupMemberLoanApplication)
    ),
  });
}

export { useRejectKycMutation };
