<script setup lang="ts">
import { CheckCircleIcon, MagnifyingGlassIcon } from '@heroicons/vue/20/solid';
import { format } from 'date-fns';
import { computed, ref } from 'vue';

import type { GroupLoanApplication } from '@/api/groupLoanApplication';
import BaseBadge from '@/components/base-badge.vue';
import BaseTabs from '@/components/base-tabs.vue';
import GroupLoanApplicationsMemberLoanContract from
  '@/components/reviews/group-loan-applications-member-loan-contract.vue';
import GroupLoanApplicationsMemberLoanInfo from
  '@/components/reviews/group-loan-applications-member-loan-info.vue';
import GroupLoanApplicationsMemberLoanKyc from
  '@/components/reviews/group-loan-applications-member-loan-kyc.vue';
import GroupMemberLoanApplicationButton from '@/components/reviews/group-member-loan-application-button.vue';

interface Props {
  groupLoanApplication: GroupLoanApplication;
}

const props = defineProps<Props>();

const selectedTabIndex = ref(0);

const KYC_DISABLED_STATES = ['inviting', 'draft', 'application_in_review'];
const CONTRACT_DISABLED_STATES = [...KYC_DISABLED_STATES, 'in_kyc', 'kyc_in_review'];

const aasmState = computed(() => props.groupLoanApplication.aasmState);

// eslint-disable-next-line complexity
const tabs = computed(() => [
  {
    title: 'Solicitudes',
    disabled: false,
    pulse: aasmState.value === 'draft',
    icon: (aasmState.value === 'application_in_review' && MagnifyingGlassIcon) ||
      (!KYC_DISABLED_STATES.includes(aasmState.value) && CheckCircleIcon) ||
      undefined,
  },
  {
    title: 'KYC',
    disabled: KYC_DISABLED_STATES.includes(aasmState.value),
    pulse: aasmState.value === 'in_kyc',
    icon: (aasmState.value === 'kyc_in_review' && MagnifyingGlassIcon) ||
      (!CONTRACT_DISABLED_STATES.includes(aasmState.value) && CheckCircleIcon) ||
      undefined,
  },
  {
    title: 'Contrato',
    disabled: CONTRACT_DISABLED_STATES.includes(aasmState.value),
    pulse: aasmState.value === 'contracts_signature_pending',
    icon: (['contracts_signed', 'disbursed'].includes(aasmState.value) && CheckCircleIcon) || undefined,
  },
]);

const groupMemberLoanApplications = computed(() => props.groupLoanApplication.memberLoanApplications);
const activeGroupMemberLoanApplications = computed(() => groupMemberLoanApplications.value.filter(
  (groupMemberLoanApplication) => groupMemberLoanApplication.aasmState !== 'rejected',
));
const rejectedGroupMemberLoanApplications = computed(() => groupMemberLoanApplications.value.filter(
  (groupMemberLoanApplication) => groupMemberLoanApplication.aasmState === 'rejected',
));
const inactiveGroupMemberLoanApplications = computed(() => props.groupLoanApplication.inactiveMemberLoanApplications);

const selectedGroupMemberLoanApplicationId = ref(activeGroupMemberLoanApplications.value[0]?.id);

const currentMemberLoanApplication = computed(() => (
  [...groupMemberLoanApplications.value, ...inactiveGroupMemberLoanApplications.value].find(
    (memberLoanApplication) => memberLoanApplication.id === selectedGroupMemberLoanApplicationId.value,
  )
));
const pendingInvitations = computed(() => props.groupLoanApplication.group.pendingInvitations);

</script>

<template>
  <base-tabs
    class="py-2"
    :selected-tab-index="selectedTabIndex"
    :tabs="tabs"
  >
    <template #tab-0>
      <div class="flex gap-x-4">
        <div class="flex shrink-0 flex-col rounded-lg bg-gray-100 p-2">
          <div
            v-if="pendingInvitations.length > 0"
            class="mb-4 border-b-2 border-gray-300 pb-4"
          >
            <h3 class="mb-4 text-xs text-gray-600">
              Invitaciones pendientes
            </h3>
            <div class="flex flex-col gap-y-4">
              <div
                v-for="invitation in pendingInvitations"
                :key="invitation.id"
                class="flex justify-between gap-x-2"
              >
                <div class="flex flex-col gap-y-2">
                  <p>
                    {{ invitation.invitee.firstName }}
                  </p>
                  <div class="flex flex-row items-center gap-x-2 text-xs text-gray-700">
                    <p><span class="font-bold">ID:</span> {{ invitation.id }}</p>
                    <p><span class="font-bold">Enviada:</span> {{ format(new Date(invitation.lastSentAt), 'dd/MM/yyyy HH:mm') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <group-member-loan-application-button
            v-for="groupMemberLoanApplication in activeGroupMemberLoanApplications"
            :key="groupMemberLoanApplication.id"
            class="w-auto"
            :ping="
              groupMemberLoanApplication.aasmState === 'submitted' &&
                groupMemberLoanApplication.preApproved === null &&
                selectedGroupMemberLoanApplicationId !== groupMemberLoanApplication.id
            "
            :group-member-loan-application="groupMemberLoanApplication"
            :selected="groupMemberLoanApplication.id === selectedGroupMemberLoanApplicationId"
            @click="selectedGroupMemberLoanApplicationId = groupMemberLoanApplication.id"
          >
            <template
              v-if="groupMemberLoanApplication.aasmState !== 'draft'"
              #badge
            >
              <base-badge
                v-if="groupMemberLoanApplication.preApproved !== null"
                :color="groupMemberLoanApplication.preApproved ? 'primary' : 'red'"
                :label="groupMemberLoanApplication.preApproved ? 'Pre-aprobado' : 'Rechazado'"
                size="xs"
              />
              <base-badge
                v-else
                color="yellow"
                label="Revisión pendiente"
                size="xs"
              />
            </template>
          </group-member-loan-application-button>
          <group-member-loan-application-button
            v-for="groupMemberLoanApplication in rejectedGroupMemberLoanApplications"
            :key="groupMemberLoanApplication.id"
            :group-member-loan-application="groupMemberLoanApplication"
            :selected="groupMemberLoanApplication.id === selectedGroupMemberLoanApplicationId"
            @click="selectedGroupMemberLoanApplicationId = groupMemberLoanApplication.id"
          />
          <group-member-loan-application-button
            v-for="groupMemberLoanApplication in inactiveGroupMemberLoanApplications"
            :key="groupMemberLoanApplication.id"
            :group-member-loan-application="groupMemberLoanApplication"
            :selected="groupMemberLoanApplication.id === selectedGroupMemberLoanApplicationId"
            @click="selectedGroupMemberLoanApplicationId = groupMemberLoanApplication.id"
          />
        </div>
        <div class="flex flex-1 rounded-lg bg-gray-100 px-2">
          <group-loan-applications-member-loan-info
            v-if="!!currentMemberLoanApplication"
            :group-member-loan-application="currentMemberLoanApplication"
          />
        </div>
      </div>
    </template>
    <template #tab-1>
      <div class="flex gap-x-4">
        <div class="flex flex-col rounded-lg bg-gray-100 p-2">
          <div
            v-if="pendingInvitations.length > 0"
            class="mb-4 border-b-2 border-gray-300 pb-4"
          >
            <h3 class="mb-4 text-xs text-gray-600">
              Invitaciones pendientes
            </h3>
            <div class="flex flex-col gap-y-4">
              <div
                v-for="invitation in pendingInvitations"
                :key="invitation.id"
                class="flex justify-between gap-x-2"
              >
                <div class="flex flex-col gap-y-2">
                  <p>
                    {{ invitation.invitee.firstName }}
                  </p>
                  <div class="flex flex-row items-center gap-x-2 text-xs text-gray-700">
                    <p><span class="font-bold">ID:</span> {{ invitation.id }}</p>
                    <p><span class="font-bold">Enviada:</span> {{ format(new Date(invitation.lastSentAt), 'dd/MM/yyyy HH:mm') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <group-member-loan-application-button
            v-for="groupMemberLoanApplication in activeGroupMemberLoanApplications"
            :key="groupMemberLoanApplication.id"
            :ping="
              groupMemberLoanApplication.aasmState === 'kyc_submitted' &&
                groupMemberLoanApplication.kycReviewStatus === 'pending_review' &&
                selectedGroupMemberLoanApplicationId !== groupMemberLoanApplication.id
            "
            :group-member-loan-application="groupMemberLoanApplication"
            :selected="groupMemberLoanApplication.id === selectedGroupMemberLoanApplicationId"
            @click="selectedGroupMemberLoanApplicationId = groupMemberLoanApplication.id"
          >
            <template
              v-if="!['draft', 'submitted', 'in_kyc'].includes(groupMemberLoanApplication.aasmState)"
              #badge
            >
              <base-badge
                v-if="groupMemberLoanApplication.kycReviewStatus !== 'pending_review'"
                color="primary"
                :label="groupMemberLoanApplication.kycReviewStatus === 'approved' ? 'KYC aprobado' : 'KYC rechazado'"
                size="xs"
              />
              <base-badge
                v-else
                color="yellow"
                label="Revisión pendiente"
                size="xs"
              />
            </template>
          </group-member-loan-application-button>
          <group-member-loan-application-button
            v-for="groupMemberLoanApplication in rejectedGroupMemberLoanApplications"
            :key="groupMemberLoanApplication.id"
            :group-member-loan-application="groupMemberLoanApplication"
            :selected="groupMemberLoanApplication.id === selectedGroupMemberLoanApplicationId"
            @click="selectedGroupMemberLoanApplicationId = groupMemberLoanApplication.id"
          />
          <group-member-loan-application-button
            v-for="groupMemberLoanApplication in inactiveGroupMemberLoanApplications"
            :key="groupMemberLoanApplication.id"
            :group-member-loan-application="groupMemberLoanApplication"
            :selected="groupMemberLoanApplication.id === selectedGroupMemberLoanApplicationId"
            @click="selectedGroupMemberLoanApplicationId = groupMemberLoanApplication.id"
          />
        </div>
        <div class="flex flex-1 rounded-lg bg-gray-100 px-2">
          <group-loan-applications-member-loan-kyc
            v-if="!!currentMemberLoanApplication"
            :group-member-loan-application="currentMemberLoanApplication"
          />
        </div>
      </div>
    </template>
    <template #tab-2>
      <div class="flex gap-x-4">
        <div class="flex flex-col rounded-lg bg-gray-100 p-2">
          <div
            v-if="pendingInvitations.length > 0"
            class="mb-4 border-b-2 border-gray-300 pb-4"
          >
            <h3 class="mb-4 text-xs text-gray-600">
              Invitaciones pendientes
            </h3>
            <div class="flex flex-col gap-y-4">
              <div
                v-for="invitation in pendingInvitations"
                :key="invitation.id"
                class="flex justify-between gap-x-2"
              >
                <div class="flex flex-col gap-y-2">
                  <p>
                    {{ invitation.invitee.firstName }}
                  </p>
                  <div class="flex flex-row items-center gap-x-2 text-xs text-gray-700">
                    <p><span class="font-bold">ID:</span> {{ invitation.id }}</p>
                    <p><span class="font-bold">Enviada:</span> {{ format(new Date(invitation.lastSentAt), 'dd/MM/yyyy HH:mm') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <group-member-loan-application-button
            v-for="groupMemberLoanApplication in activeGroupMemberLoanApplications"
            :key="groupMemberLoanApplication.id"
            :group-member-loan-application="groupMemberLoanApplication"
            :selected="groupMemberLoanApplication.id === selectedGroupMemberLoanApplicationId"
            :aasm-state-to-show="
              groupMemberLoanApplication.aasmState === 'contract_signature_pending' ?
                'contract_signature_pending' : 'contract_signed'
            "
            @click="selectedGroupMemberLoanApplicationId = groupMemberLoanApplication.id"
          />
          <group-member-loan-application-button
            v-for="groupMemberLoanApplication in rejectedGroupMemberLoanApplications"
            :key="groupMemberLoanApplication.id"
            :group-member-loan-application="groupMemberLoanApplication"
            :selected="groupMemberLoanApplication.id === selectedGroupMemberLoanApplicationId"
            @click="selectedGroupMemberLoanApplicationId = groupMemberLoanApplication.id"
          />
          <group-member-loan-application-button
            v-for="groupMemberLoanApplication in inactiveGroupMemberLoanApplications"
            :key="groupMemberLoanApplication.id"
            :group-member-loan-application="groupMemberLoanApplication"
            :selected="groupMemberLoanApplication.id === selectedGroupMemberLoanApplicationId"
            @click="selectedGroupMemberLoanApplicationId = groupMemberLoanApplication.id"
          />
        </div>
        <div class="flex flex-1 rounded-lg bg-gray-100 px-2">
          <group-loan-applications-member-loan-contract
            v-if="!!currentMemberLoanApplication"
            :group-member-loan-application="currentMemberLoanApplication"
          />
        </div>
      </div>
    </template>
  </base-tabs>
</template>
