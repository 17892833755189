<script setup lang="ts">
import { ref } from 'vue';

import type { GroupConnections } from '@/api/group';
import type { CompositionChanges, ConsolidatedLoan, GroupLoanApplication } from '@/api/groupLoanApplication';
import type { PromoterPresidentInvite } from '@/api/promoterPresidentInvite';
import BaseTabs from '@/components/base-tabs.vue';
import ConsolidatedGroupLoans from '@/components/reviews/consolidated-group-loans.vue';
import GroupLoanApplicationCompositionChanges from
  '@/components/reviews/group-loan-application-composition-changes.vue';
import GroupLoanApplicationExternalConnections
  from '@/components/reviews/group-loan-application-external-connections.vue';
import GroupLoanApplicationGeolocations from '@/components/reviews/group-loan-application-geolocations.vue';
import GroupLoanApplicationPromoterInput
  from '@/components/reviews/group-loan-application-promoter-input.vue';
import GroupLoanApplicationSummary from '@/components/reviews/group-loan-application-summary.vue';

interface Props {
  groupLoanApplication: GroupLoanApplication;
  groupConnections: GroupConnections;
  promoterPresidentInvite?: PromoterPresidentInvite;
  consolidatedLoans: ConsolidatedLoan[];
  compositionChanges: CompositionChanges;
}

const props = defineProps<Props>();

const AASM_STATES_WITHOUT_GEOLOCATION = ['inviting', 'draft'];

const selectedTabIndex = ref(0);

const TABS = [
  { title: 'Integrantes' },
  { title: 'Conexiones externas' },
  { title: 'Input asesor' },
  { title: 'Consolidaciones' },
  !AASM_STATES_WITHOUT_GEOLOCATION.includes(props.groupLoanApplication.aasmState) && { title: 'Ubicaciones' },
  props.groupLoanApplication.cycle > 1 && { title: 'Cambios de composición' },
].filter(Boolean) as Array<{ title: string }>;

</script>

<template>
  <base-tabs
    :selected-tab-index="selectedTabIndex"
    :tabs="TABS"
  >
    <template #tab-0>
      <group-loan-application-summary
        class="mt-6"
        :group-loan-application="groupLoanApplication"
        :group-members="groupLoanApplication.group.members"
      />
    </template>
    <template #tab-1>
      <group-loan-application-external-connections
        class="max-h-96 overflow-y-auto"
        :group-connections="groupConnections"
        :group="groupLoanApplication.group"
      />
    </template>
    <template #tab-2>
      <group-loan-application-promoter-input :promoter-president-invite="promoterPresidentInvite" />
    </template>
    <template #tab-3>
      <consolidated-group-loans
        class="max-h-96 overflow-y-auto"
        :group-loan-application="groupLoanApplication"
        :consolidated-loans="consolidatedLoans"
      />
    </template>
    <template #tab-4>
      <group-loan-application-geolocations :group-loan-application="groupLoanApplication" />
    </template>
    <template #tab-5>
      <group-loan-application-composition-changes
        :composition-changes="compositionChanges"
      />
    </template>
  </base-tabs>
</template>
