<script setup lang="ts">
import { ref } from 'vue';

import type { GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';
import BaseTabs from '@/components/base-tabs.vue';
import ConsolidatedCreditReport from '@/components/reviews/consolidated-credit-report.vue';
import GroupLoanApplicationsMemberLoanFormTab
  from '@/components/reviews/group-loan-applications-member-loan-form-tab.vue';

interface Props {
  groupMemberLoanApplication: GroupMemberLoanApplication
}

defineProps<Props>();
const selectedTab = ref(0);
</script>

<template>
  <base-tabs
    class="w-full"
    :tabs="[{ title: 'Solicitud' }, { title: 'Reporte CC' }]"
    :selected-tab-index="selectedTab"
  >
    <template #tab-0>
      <group-loan-applications-member-loan-form-tab :group-member-loan-application="groupMemberLoanApplication" />
    </template>
    <template #tab-1>
      <consolidated-credit-report
        v-if="groupMemberLoanApplication.consolidatedCreditReport"
        :group-member-loan-application="groupMemberLoanApplication"
      />
      <template v-else-if="!groupMemberLoanApplication.creditConsultationConsent">
        <p class="p-8 text-center text-lg">
          Aún <span class="font-bold text-purple-700">no</span> se ha otorgado el consentimiento para la consulta de buró de crédito
        </p>
      </template>
      <template v-else>
        <p class="p-8 text-center text-lg">
          Hubo un error al cargar el reporte de buró de crédito
        </p>
      </template>
    </template>
  </base-tabs>
</template>
