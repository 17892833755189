<script setup lang="ts">
import { useForm } from 'vee-validate';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { object, mixed } from 'yup';

import { type PromoterPresidentInvite } from '@/api/promoterPresidentInvite';
import { useUpdatePromoterPresidentInvite } from '@/api/promoterPresidentInvite/mutations';
import BaseHeaderTable from '@/components/base-header-table.vue';
import baseImageWithControls from '@/components/base-image-with-controls.vue';
import VeeValidateFileInput from '@/components/vee-validate/vee-validate-file-input.vue';
import useUiStore from '@/stores/ui';

const validationSchema = object({
  'paymentSheetFile': mixed().required('El archivo es requerido'),
});

interface Props {
  promoterPresidentInvite?: PromoterPresidentInvite;
}

const props = defineProps<Props>();

const { t } = useI18n();
const { handleSubmit } = useForm({ validationSchema });
const uiStore = useUiStore();

const updatePromoterPresidentInviteMutation = useUpdatePromoterPresidentInvite({
  id: props.promoterPresidentInvite?.id,
  onSuccess: () => {
    window.location.reload();

    uiStore.toast({
      message: 'Documento subido',
      type: 'success',
      position: 'top',
    });
  },
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Hubo un error al subir el documento',
      type: 'error',
      position: 'top',
    });
  },
});

const {
  knownSince, knownFrom, additionalInformation, previousCreditInstitution, paymentSheet,
} = (props.promoterPresidentInvite ?? {});

const showPromoterInfo = computed(() => (
  knownSince || knownFrom || additionalInformation || previousCreditInstitution || paymentSheet
));

function getKnownSinceLabel() {
  return knownSince ? t(`promoterPresidentInvites.knownSince.${knownSince}`) : '';
}

function getKnownFromLabel() {
  return knownFrom ? t(`promoterPresidentInvites.knownFrom.${knownFrom}`) : '';
}

const onSubmit = handleSubmit((values) => {
  updatePromoterPresidentInviteMutation.mutate(values);
});
</script>

<template>
  <div class="max-w-[68rem] rounded-lg bg-gray-100">
    <div
      v-if="showPromoterInfo"
      className="flex flex-col gap-y-4"
    >
      <base-header-table
        title=""
        size="sm"
        :headers="['Conoce al grupo porque', 'Las conoce hace', 'Financiera anterior', 'Información adicional']"
        :rows="[[
          getKnownFromLabel(),
          getKnownSinceLabel(),
          previousCreditInstitution ?? '',
          additionalInformation ?? '',
        ]]"
      />
      <div class="flex gap-x-5 p-4">
        <base-image-with-controls
          v-if="paymentSheet?.fileUrl"
          :src="paymentSheet.fileUrl"
          caption="Hoja de pagos"
          class="max-w-32"
        />
        <form
          v-else
          class="flex flex-col gap-y-2"
          @submit="onSubmit"
        >
          <vee-validate-file-input
            label="Adjunta la hoja de pago"
            name="paymentSheetFile"
            extensions=".JPG, .PNG, .JPEG"
            input-id="paymentSheet"
          />
          <base-button text="Subir" />
        </form>
        <div
          v-if="promoterPresidentInvite?.paymentSheetNameMatches?.length"
          className="flex flex-col gap-y-1"
        >
          <p class="text-sm font-semibold text-gray-700">
            Miembros en la sábana
          </p>
          <ul class="ml-5 list-disc">
            <li
              v-for="nameMatch in promoterPresidentInvite.paymentSheetNameMatches"
              :key="nameMatch.groupMemberId"
            >
              {{ nameMatch.fullName }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <p
      v-else
      class="py-8 text-center text-gray-700"
    >
      El asesor no proporcionó información
    </p>
  </div>
</template>
