import { api } from '@/api';

interface BaseUser {
  id: number;
  firstName: string | null;
  paternalSurname: string | null;
  maternalSurname: string | null;
  identificationNumber: string | null;
  email: string | null;
  fullName: string | null;
  phoneNumber: string;
  birthDate: string | null;
  sex: 'male' | 'female' | null;
  country: string | null;
  city: string | null;
  state: string | null;
  street: string | null;
  externalNumber: string | null;
  internalNumber: string | null;
  colony: string | null;
  municipality: string | null;
  postalCode: string | null;
  address: string | null;
  collectionAgentMessagesEnabled: boolean;
}

type User = BaseUser

interface LoginParams {
  phoneNumber: string;
  magicCode: string;
}

type ValidateMagicCodeResponse = { user: User, redirectPath: string }

function login(body: LoginParams) {
  const path = '/api/internal/sessions';

  return api.post<ValidateMagicCodeResponse>(path, body);
}

function createMagicCode(phoneNumber: LoginParams['phoneNumber']) {
  const path = '/api/internal/users/magic_codes';

  return api.post<void>(path, { phoneNumber });
}

function validateMagicCode(phoneNumber: LoginParams['phoneNumber'], magicCode: LoginParams['magicCode']) {
  const path = '/api/internal/sessions';

  return api.post<ValidateMagicCodeResponse>(path, { phoneNumber, magicCode });
}

export type { LoginParams, ValidateMagicCodeResponse, BaseUser, User };
export { login, createMagicCode, validateMagicCode };
