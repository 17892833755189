<script setup lang="ts">
import { CheckIcon } from '@heroicons/vue/24/outline';
import { ref, watch, nextTick } from 'vue';

import type { WhatsappConversation } from '@/api/crm/whatsapp/conversation';

interface Props {
  conversation: WhatsappConversation;
}

const props = defineProps<Props>();
const messagesContainerRef = ref<HTMLElement | null>(null);

watch(() => props.conversation.recipientPhone, () => {
  nextTick(() => {
    if (messagesContainerRef.value) {
      messagesContainerRef.value.scrollTop = messagesContainerRef.value.scrollHeight;
    }
  });
}, { immediate: true });
</script>

<template>
  <ul
    ref="messagesContainerRef"
    class="flex grow flex-col gap-y-4 overflow-y-scroll p-4"
  >
    <li
      v-for="message in conversation.messages"
      :key="message.timestamp"
      class="flex flex-col"
    >
      <div
        v-if="['api', 'sent'].includes(message.origin)"
        class="flex flex-col gap-y-0.5 self-end"
      >
        <div
          class="flex w-full min-w-52 flex-col rounded-lg bg-green-200 px-4 py-2"
        >
          <p
            v-if="message.messageType === 'text'"
            class="whitespace-pre-line text-gray-700"
          >
            {{ message.text }}
          </p>
          <span
            v-else-if="message.messageType === 'interactive'"
            class="italic text-gray-600"
          >
            Mensaje interactivo
          </span>
          <img
            v-else-if="message.messageType === 'image'"
            :src="message.imageUrl"
            class="h-48 w-auto"
          >
        </div>
        <span class="mr-1 self-end text-xs italic text-gray-600">
          {{ $filters.formatTime(message.timestamp, "yyyy-MM-dd H:mm") }}
        </span>
      </div>
      <div
        v-else
        class="flex flex-col gap-y-0.5 self-start"
      >
        <div
          class="flex min-w-[200px] flex-col rounded-lg bg-gray-100 px-4 py-2"
        >
          <p
            v-if="message.messageType === 'text'"
            class="whitespace-pre-line text-gray-700"
          >
            {{ message.text }}
          </p>
          <span
            v-else-if="message.messageType === 'interactive'"
            class="italic text-gray-600"
          >
            Respuesta a mensaje interactivo
          </span>
          <span
            v-else-if="message.messageType === 'quick_reply'"
            class="italic text-gray-600"
          >
            {{ message.webhookPayload }}
          </span>
          <img
            v-else-if="message.messageType === 'image'"
            :src="message.imageUrl"
            class="h-48 w-auto"
          >
          <div
            :class="[
              'flex flex-row self-end',
              message.read ? 'text-blue-400' : 'text-gray-400',
            ]"
          >
            <CheckIcon class="size-4" />
            <CheckIcon class="-ml-2 size-4" />
          </div>
        </div>
        <span class="mr-1 self-end text-xs italic text-gray-600">
          {{ $filters.formatTime(message.timestamp, "yyyy-MM-dd H:mm") }}
        </span>
      </div>
    </li>
  </ul>
</template>
