import { type UseMutationOptions } from '@tanstack/vue-query';

import type { ApiError } from '@/api';
import {
  createMagicCode,
  validateMagicCode,
  type ValidateMagicCodeResponse,
  type LoginParams,
} from '@/api/user';
import useReactiveMutation from '@/composables/useReactiveMutation';

type CreateMagicCodeMutationArgs = UseMutationOptions<void, ApiError, string>;

function useCreateMagicCodeMutation(options: CreateMagicCodeMutationArgs = {}) {
  return useReactiveMutation<void, ApiError, string>({
    ...options,
    mutationFn: (phoneNumber) => createMagicCode(phoneNumber).then(response => response.data),
  });
}

type ValidateMagicCodeMutationArgs = UseMutationOptions<
  ValidateMagicCodeResponse,
  ApiError,
  LoginParams
>;

function useValidateMagicCodeMutation(options: ValidateMagicCodeMutationArgs = {}) {
  return useReactiveMutation({
    ...options,
    mutationFn: ({ phoneNumber, magicCode }) => validateMagicCode(phoneNumber, magicCode)
      .then(response => response.data),
  });
}

export { useCreateMagicCodeMutation, useValidateMagicCodeMutation };
