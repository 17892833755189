<script setup lang="ts">
import { ref, type Component } from 'vue';

import BaseIcon from '@/components/base-icon.vue';

interface Tab {
  title: string;
  disabled?: boolean;
  pulse?: boolean;
  icon?: string | Component;
}

interface Props {
  tabs: Tab[];
  selectedTabIndex: number;
}

const props = defineProps<Props>();

const selectedTabIndex = ref(props.selectedTabIndex || 0);
</script>

<template>
  <div>
    <div class="sm:hidden">
      <select
        id="tabs"
        v-model="selectedTabIndex"
        name="tabs"
        class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
      >
        <option
          v-for="(tab, index) in tabs"
          :key="index"
          :value="index"
        >
          {{ tab.title }}
        </option>
      </select>
    </div>
    <div class="hidden sm:block">
      <div class="mb-4 border-b border-gray-200">
        <nav
          class="-mb-px flex"
          aria-label="Tabs"
        >
          <button
            v-for="(tab, index) in tabs"
            :key="index"
            :disabled="tab.disabled"
            class="flex items-center whitespace-nowrap border-b-2 px-8 py-4 text-sm font-medium"
            :class="{
              'border-primary-500 text-primary-600': selectedTabIndex === index,
              'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700': selectedTabIndex !== index,
              'cursor-not-allowed opacity-50': tab.disabled,
              'cursor-pointer': !tab.disabled,
              'animate-pulse': tab.pulse,
            }"
            @click.prevent="selectedTabIndex = index"
          >
            <base-icon
              v-if="tab.icon"
              :icon="tab.icon"
              class="mr-2 size-5"
            />
            <p>{{ tab.title }}</p>
          </button>
        </nav>
      </div>
    </div>
    <slot :name="`tab-${selectedTabIndex}`" />
  </div>
</template>
