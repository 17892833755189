<script setup lang="ts">
import { ArrowTopRightOnSquareIcon, ArrowRightIcon, ArrowLeftIcon } from '@heroicons/vue/24/outline';
import { ref, watch, computed, nextTick } from 'vue';

import { useMarkAsRead, useSendReply } from '@/api/crm/whatsapp/conversation/mutations';
import { useFetchConversation } from '@/api/crm/whatsapp/conversation/queries';
import CrmWhatsappContactsList from '@/components/crm/whatsapp/contacts-list.vue';
import CrmWhatsappMessagesBox from '@/components/crm/whatsapp/messages-box.vue';
import VFormNativeWrapper from '@/components/v-form-native-wrapper.vue';
import VForm from '@/components/v-form.vue';

export interface Conversation {
  recipientPhone: string;
  name: string;
  unreadMessagesCount: number;
}

interface Props {
  conversations: Conversation[];
}

const REFRESH_INTERVAL = 2000;

const props = defineProps<Props>();
const conversationsRef = ref(props.conversations);

const queryParams = new URLSearchParams(window.location.search);

const selectedConversation = ref(conversationsRef.value[0]);
const recipientPhone = computed(() => selectedConversation.value?.recipientPhone);
const message = ref('');

const pageParam = queryParams.get('page') || '1';
const page = ref(parseInt(pageParam, 10));
const pageFormRef = ref<InstanceType<typeof VFormNativeWrapper> | null>(null);
function changePage(newPage: number) {
  if (newPage !== page.value) {
    page.value = newPage;
    nextTick(() => pageFormRef.value?.innerFormRef?.submit());
  }
}

const account = ref(queryParams.get('account') || 'main');
function changeAccount(value: string) {
  window.location.search = `?account=${value}&page=${page.value}`;
}

const conversationQ = useFetchConversation({
  recipientPhone: computed(() => selectedConversation.value?.recipientPhone),
  account: account.value,
  refetchInterval: REFRESH_INTERVAL,
});

const conversationUser = computed(() => conversationQ.data?.user);

const sendReplyMutation = useSendReply({ recipientPhone, account });
const markAsReadMutation = useMarkAsRead({
  recipientPhone,
  account,
  onSuccess: () => {
    conversationsRef.value = conversationsRef.value.map((conversation) => {
      if (conversation.recipientPhone === selectedConversation.value.recipientPhone) {
        return {
          ...conversation,
          unreadMessagesCount: 0,
        };
      }

      return conversation;
    });
  },
});

watch(
  selectedConversation,
  () => markAsReadMutation.mutate(),
  { immediate: true },
);

const accountOptions = [
  {
    label: 'Principal',
    value: 'main',
  },
  {
    label: 'Bot de cobranza',
    value: 'collection',
  },
];
</script>

<template>
  <div class="flex size-full grow flex-row">
    <div class="flex flex-col gap-y-2">
      <select
        name="account"
        :value="account"
        @input="(event: Event) => changeAccount((event.target as HTMLSelectElement).value)"
      >
        <option
          v-for="option in accountOptions"
          :key="option.value"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
      <crm-whatsapp-contacts-list
        :conversations="conversationsRef"
        :selected-conversation="selectedConversation"
        :account="account"
        :page="page"
        @update:account="changeAccount"
        @update:selected-conversation="selectedConversation = $event"
      />
      <v-form-native-wrapper
        ref="pageFormRef"
        class="mt-2 flex flex-row items-center justify-center gap-x-4"
        method="GET"
        :action="`/crm/whatsapp/conversations?page=${page}&account=${account}`"
      >
        <input
          type="hidden"
          name="page"
          :value="page"
        >
        <button
          type="submit"
          class="flex items-center justify-center rounded border border-primary-600 bg-white p-1"
          :disabled="page === 1"
          @click="changePage(page - 1)"
        >
          <ArrowLeftIcon class="size-5" />
        </button>
        <span>
          {{ page }}
        </span>
        <button
          class="flex items-center justify-center rounded border border-primary-600 bg-white p-1"
          @click="changePage(page + 1)"
        >
          <ArrowRightIcon class="size-5" />
        </button>
      </v-form-native-wrapper>
    </div>
    <div
      v-if="conversationQ.data"
      class="flex size-full flex-col bg-primary-50 p-1"
    >
      <div
        class="flex w-full flex-row justify-between gap-x-4 bg-primary-600 p-4"
      >
        <div class="flex flex-col">
          <span class="text-white">
            {{ conversationUser?.fullName || "Anónimo" }}
          </span>
          <span class="text-gray-200">
            {{ selectedConversation.recipientPhone }}
          </span>
        </div>
        <div class="flex flex-row items-center gap-x-6">
          <a
            v-if="conversationUser"
            :href="`/admin/users/${conversationUser.id}`"
            target="_blank"
            class="flex flex-row items-center gap-x-2 text-white"
          >
            <span class="text-sm"> Admin </span>
            <ArrowTopRightOnSquareIcon class="size-6" />
          </a>
          <a
            :href="`https://wa.me/${selectedConversation.recipientPhone}`"
            target="_blank"
            class="flex flex-row items-center gap-x-2 text-white"
          >
            <span class="text-sm"> WhatsApp </span>
            <ArrowTopRightOnSquareIcon class="size-6" />
          </a>
          <div
            v-if="account === 'collection'"
            class="flex flex-row gap-x-2"
          >
            <span class="text-sm text-gray-200">
              {{
                conversationUser?.collectionAgentMessagesEnabled
                  ? "Desactivar agente"
                  : "Activar agente"
              }}
            </span>
          </div>
        </div>
      </div>
      <crm-whatsapp-messages-box :conversation="conversationQ.data" />
      <v-form
        class="flex w-full flex-row gap-x-2 rounded-lg bg-white focus-within:ring-1 focus-within:ring-primary-600"
        @submit="() => sendReplyMutation.mutate(message)"
      >
        <textarea
          v-model="message"
          name="message"
          class="textarea-no-outline w-full border-none bg-transparent p-1 text-left text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
        />
        <base-button
          variant="white"
          text="Enviar"
          type="submit"
        />
      </v-form>
    </div>
  </div>
</template>

<style>
.textarea-no-outline:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
</style>
