type FormDataValue = string | number | boolean | File | Blob | Date | Array<FormDataValue>;
type BaseTPayload = object | FormDataValue;

// eslint-disable-next-line complexity
function addToFormData<TPayload = BaseTPayload>(formData: FormData, data: TPayload, prefix: string = ''): void {
  if (data instanceof File || data instanceof Blob) {
    formData.append(prefix, data);
  } else if (data instanceof Array) {
    data.forEach((value) => {
      addToFormData(formData, value, `${prefix}[]`);
    });
  } else if (data instanceof Date) {
    formData.append(prefix, data.toISOString().split('T')[0]);
  } else if (typeof data === 'object' && data !== null) {
    Object.entries(data).forEach(([key, value]) => {
      const newPrefix = prefix ? `${prefix}[${key}]` : key;
      addToFormData(formData, value, newPrefix);
    });
  } else if (typeof data === 'boolean') {
    formData.append(prefix, data.toString());
  } else if (data !== undefined && data !== null) {
    formData.append(prefix, data.toString());
  }
}

export default addToFormData;
