import type { AddressProof, BackIdCard, KycDocument, FrontIdCard, SelfieIdCard } from '@/api/kycDocument';

function isFrontIdCard(kycDocument: KycDocument): kycDocument is FrontIdCard {
  return kycDocument.kind === 'front_id_card';
}

function isBackIdCard(kycDocument: KycDocument): kycDocument is BackIdCard {
  return kycDocument.kind === 'back_id_card';
}

function isSelfieIdCard(kycDocument: KycDocument): kycDocument is SelfieIdCard {
  return kycDocument.kind === 'selfie_id_card';
}

function isAddressProof(kycDocument: KycDocument): kycDocument is AddressProof {
  return kycDocument.kind === 'address_proof';
}

export { isFrontIdCard, isBackIdCard, isSelfieIdCard, isAddressProof };
