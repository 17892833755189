<script setup lang="ts">
import { ArrowPathRoundedSquareIcon, TrashIcon, ClockIcon } from '@heroicons/vue/24/outline';
import { formatDistanceToNow } from 'date-fns';
import { ref, computed } from 'vue';

import type { GroupConnections } from '@/api/group';
import { useFetchGroupLoans } from '@/api/groupLoan/queries';
import { type CompositionChanges, type ConsolidatedLoan, type GroupLoanApplication } from '@/api/groupLoanApplication';
import {
  useGenerateUnsignedContracts,
  useUpdateGroupLoanApplication,
} from '@/api/groupLoanApplication/reviews/mutations';
import type { PromoterPresidentInvite } from '@/api/promoterPresidentInvite';
import { useCreateTags } from '@/api/tags/mutations';
import { useFetchTags } from '@/api/tags/queries';
import BaseBadge from '@/components/base-badge.vue';
import BaseButton from '@/components/base-button.vue';
import BaseListBox from '@/components/base-list-box.vue';
import BaseModal from '@/components/base-modal.vue';
import GroupLoanApplicationCancellationModal from '@/components/reviews/group-loan-application-cancellation-modal.vue';
import GroupLoanApplicationHeaderTabs from '@/components/reviews/group-loan-application-header-tabs.vue';
import GroupLoanApplicationRejectionModal from '@/components/reviews/group-loan-application-rejection-modal.vue';
import useUIStore from '@/stores/ui';
import { groupLoanApplicationBadgeColor } from '@/utils/states';

const REJECTED_OR_CANCELED = ['application_rejected', 'canceled'];

const STATE_MAPPING = [
  { name: 'Invitaciones', value: 'inviting', order: 0 },
  { name: 'Draft', value: 'draft', order: 1 },
  { name: 'Review', value: 'application_in_review', order: 2 },
  { name: 'KYC', value: 'in_kyc', order: 3 },
];

type ListBoxAasmStates = 'inviting' | 'draft' | 'application_in_review' | 'in_kyc';
interface FireAasmStateUpdatePayload {
  aasmStateEvent: 'start_kyc'
  | 'accept_kyc'
  | 'pass_application_to_review'
  | 'reject_application'
  | 'review_kyc'
  | 'disburse'
  | 'cancel'
  | 'restart_application';
}
interface Props {
  rejectionTags: string[];
  groupLoanApplication: GroupLoanApplication;
  previousGroupLoanApplications: GroupLoanApplication[];
  groupConnections: GroupConnections;
  promoterPresidentInvite?: PromoterPresidentInvite;
  consolidatedLoans: ConsolidatedLoan[];
  compositionChanges: CompositionChanges;
}

const props = defineProps<Props>();

const group = computed(() => props.groupLoanApplication.group);

const uiStore = useUIStore();

const memberLoanApplicationsInKyc = computed(() => props.groupLoanApplication.memberLoanApplications.filter(
  (memberLoanApplication) => ['kyc_submitted', 'kyc_in_review'].includes(memberLoanApplication.aasmState),
));

const areAllContractsSentToGenerate = computed(() => memberLoanApplicationsInKyc.value.every(
  (memberLoanApplication) => memberLoanApplication.unsignedContractGenerateJobStatus !== null,
));
const areAllContractsGenerated = computed(() => memberLoanApplicationsInKyc.value.every(
  (memberLoanApplication) => !!memberLoanApplication.unsignedContractUrl,
));

const rejectionTagsQ = useFetchTags({
  resourceId: props.groupLoanApplication.id,
  resourceType: 'GroupLoanApplication',
  context: 'rejection_tags',
});
const updateGroupLoanApplicationAasmStateMutation = useUpdateGroupLoanApplication<{
  aasmState: ListBoxAasmStates
}>({
  id: props.groupLoanApplication.id,
  onSuccess: () => {
    uiStore.toast({
      message: 'Solicitud actualizada',
      type: 'success',
      position: 'top',
    });
  },
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Hubo un error al editar la solicitud',
      type: 'error',
      position: 'top',
    });
  },
});

const createTagsMutation = useCreateTags({
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Hubo un error al crear los tags',
      type: 'error',
      position: 'top',
    });
  },
});

const fireAasmStateEventMutation = useUpdateGroupLoanApplication<FireAasmStateUpdatePayload>({
  id: props.groupLoanApplication.id,
  onSuccess: (_, { aasmStateEvent }) => {
    const message = {
      'start_kyc': 'Solicitud enviada a KYC',
      'accept_kyc': 'KYC aprobado',
      'pass_application_to_review': 'Solicitud enviada a revisión',
      'reject_application': 'Solicitud rechazada',
      'review_kyc': 'KYC pasado a revisión',
      'restart_application': 'Solicitud iniciada',
      'disburse': 'Crédito marcado como desembolsado',
      'cancel': 'Solicitud cancelada',
    }[aasmStateEvent];

    uiStore.toast({
      message,
      type: 'success',
      position: 'top',
    });
  },
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Hubo un error al editar la solicitud',
      type: 'error',
      position: 'top',
    });
  },
});

const {
  mutate: generateUnsignedContracts, isPending: isGenerateUnsignedContractsPending,
} = useGenerateUnsignedContracts({
  groupLoanApplicationId: props.groupLoanApplication.id,
  onSuccess: () => {
    uiStore.toast({
      message: 'Contratos enviados a generar correctamente.',
      type: 'success',
      position: 'top',
    });
  },
  onError: () => {
    uiStore.toast({
      message: 'Hubo un error al generar los contratos',
      type: 'error',
      position: 'top',
    });
  },
});

const currentListBoxOption = computed(() => STATE_MAPPING.find(
  (option) => option.value === props.groupLoanApplication.aasmState),
);

const listBoxOptions = computed(() => {
  if (!currentListBoxOption.value) {
    return [];
  }

  return STATE_MAPPING.filter((option) => option.order <= (currentListBoxOption.value?.order ?? 0),
  );
});

const rejectApplicationConfirmationModalOpen = ref(false);
function rejectApplication(values: { rejectionTags: string[] }) {
  createTagsMutation.mutate({
    resourceId: props.groupLoanApplication.id,
    resourceType: 'GroupLoanApplication',
    context: 'rejection_tags',
    tags: values.rejectionTags,
  });

  fireAasmStateEventMutation.mutate({ aasmStateEvent: 'reject_application' });
  rejectApplicationConfirmationModalOpen.value = false;
}

const passApplicationToReviewConfirmationModalOpen = ref(false);
function passApplicationToReview() {
  fireAasmStateEventMutation.mutate({ aasmStateEvent: 'pass_application_to_review' });
  passApplicationToReviewConfirmationModalOpen.value = false;
}

const passApplicationToKycInReviewConfirmationModalOpen = ref(false);
function passApplicationToKycInReview() {
  fireAasmStateEventMutation.mutate({ aasmStateEvent: 'review_kyc' });
  passApplicationToKycInReviewConfirmationModalOpen.value = false;
}

const cancelApplicationConfirmationModalOpen = ref(false);
function cancelApplication(values: { cancellationReason: string }) {
  fireAasmStateEventMutation.mutate({ ...values, aasmStateEvent: 'cancel' });
  cancelApplicationConfirmationModalOpen.value = false;
}

const canGenerateContracts = computed(() => memberLoanApplicationsInKyc.value.every(
  (memberLoanApplication) => memberLoanApplication.kycReviewStatus === 'approved',
));

const previousDisbursedGroupLoanApplications = computed(() => props.previousGroupLoanApplications.filter(
  (groupLoanApplication) => !REJECTED_OR_CANCELED.includes(groupLoanApplication.aasmState),
));
const previousRejectedGroupLoanApplications = computed(() => props.previousGroupLoanApplications.filter(
  (groupLoanApplication) => REJECTED_OR_CANCELED.includes(groupLoanApplication.aasmState),
));

const fetchGroupLoansQ = useFetchGroupLoans({ groupLoanApplicationId: props.groupLoanApplication.id });
</script>

<template>
  <div class="flex w-full flex-col gap-y-4">
    <div class="flex flex-col gap-y-1 md:flex-row md:items-center md:gap-x-4">
      <h1 class="text-lg">
        <span class="font-bold">{{ group.name }}</span> ({{ group.publicId }})
      </h1>
      <base-badge
        v-if="group.promoter"
        :label="group.promoter.fullName"
        color="gray"
      />
      <BaseBadge
        v-if="areAllContractsGenerated && groupLoanApplication.aasmState === 'kyc_in_review'"
        label="Contratos generados"
      />
      <base-badge
        v-else
        :label="$t(`groupLoanApplication.aasmState.${groupLoanApplication.aasmState}`)"
        :color="groupLoanApplicationBadgeColor(groupLoanApplication.aasmState)"
      />
    </div>
    <div class="flex items-center gap-x-2">
      <a
        class="cursor-pointer text-blue-500 hover:underline"
        target="_blank"
        :href="`/admin/group_loan_applications/${groupLoanApplication.id}`"
      >
        Solicitud {{ groupLoanApplication.id }}
      </a>
      <div
        v-if="groupLoanApplication.submittedAt"
        class="flex flex-row items-center gap-x-1"
      >
        <p class="text-gray-700">
          - enviada
          {{ formatDistanceToNow(new Date(groupLoanApplication.submittedAt), { addSuffix: true }) }}
        </p>
        <ClockIcon
          class="size-4 text-primary-600/70"
        />
      </div>
    </div>
    <div
      v-if="groupLoanApplication.cycle > 1"
      class="flex flex-col gap-y-2"
    >
      <div class="flex flex-row items-center gap-x-1">
        <ArrowPathRoundedSquareIcon class="size-4" />
        <span class="text-sm">
          Renovación (ciclo {{ groupLoanApplication.cycle }})
        </span>
      </div>
      <ul class="flex flex-row items-center gap-x-2">
        <p>
          Ciclos anteriores:
        </p>
        <li
          v-for="prevGroupLoanApplication in previousDisbursedGroupLoanApplications"
          :key="prevGroupLoanApplication.id"
          class="mt-px"
        >
          <a
            :href="`/reviews/group_loan_applications/${prevGroupLoanApplication.id}`"
            target="_blank"
            class="text-blue-400 hover:opacity-75"
          >
            {{ prevGroupLoanApplication.cycle }}
          </a>
        </li>
      </ul>
      <ul class="flex flex-row items-center gap-x-2">
        <p>
          Solicitudes rechazadas:
        </p>
        <li
          v-for="prevGroupLoanApplication in previousRejectedGroupLoanApplications"
          :key="prevGroupLoanApplication.id"
          class="mt-px"
        >
          <a
            :href="`/reviews/group_loan_applications/${prevGroupLoanApplication.id}`"
            target="_blank"
            class="text-blue-400 hover:opacity-75"
          >
            {{ prevGroupLoanApplication.cycle }} ({{ prevGroupLoanApplication.id }})
          </a>
        </li>
      </ul>
    </div>
    <div
      v-if="fetchGroupLoansQ.data?.[0] && fetchGroupLoansQ.data.length === 1"
      class="flex gap-x-1"
    >
      <p>
        Ir al admin del
      </p>
      <a
        :href="`/admin/group_loans/${fetchGroupLoansQ.data[0].id}`"
        class="text-blue-500"
        target="_blank"
      >
        GroupLoan
      </a>
    </div>
    <div
      v-if="props.groupLoanApplication.aasmState === 'canceled' || rejectionTagsQ?.data?.length"
      class="flex gap-x-2"
    >
      <base-badge
        v-for="rejectionTag in rejectionTagsQ.data"
        :key="rejectionTag.id"
        color="red"
        :label="$t(`groupLoanApplication.rejectionTags.${rejectionTag.name}`)"
        size="xs"
      />
      <p
        v-if="props.groupLoanApplication.aasmState === 'canceled'"
        class="text-primary-600"
      >
        Cancelada porque
        <span class="italic text-gray-700">
          {{ props.groupLoanApplication.cancellationReason }}
        </span>
      </p>
    </div>
    <div class="flex flex-row items-center gap-x-2">
      <base-button
        v-if="groupLoanApplication.aasmState === 'draft'"
        text="Pasar a revisión"
        class="self-start"
        :loading="fireAasmStateEventMutation.isPending"
        @click="() => passApplicationToReviewConfirmationModalOpen = true"
      />
      <base-button
        v-else-if="groupLoanApplication.aasmState === 'application_in_review'"
        text="Pasar a KYC"
        class="self-start"
        :loading="fireAasmStateEventMutation.isPending"
        @click="() => fireAasmStateEventMutation.mutate({ aasmStateEvent: 'start_kyc' })"
      />
      <base-button
        v-else-if="groupLoanApplication.aasmState === 'in_kyc'"
        text="Pasar KYC a revisión"
        class="w-full self-start md:w-auto"
        :loading="fireAasmStateEventMutation.isPending"
        @click="passApplicationToKycInReviewConfirmationModalOpen = true"
      />
      <template v-else-if="groupLoanApplication.aasmState === 'kyc_in_review'">
        <base-button
          v-if="areAllContractsGenerated"
          text="Aprobar KYC"
          :loading="fireAasmStateEventMutation.isPending"
          class="w-full self-start md:w-auto"
          @click="() => fireAasmStateEventMutation.mutate({ aasmStateEvent: 'accept_kyc' })"
        />
        <base-button
          v-else-if="areAllContractsSentToGenerate"
          text="Generando contratos"
          :disabled="true"
          :loading="isGenerateUnsignedContractsPending"
          class="w-full self-start md:w-auto"
          @click="generateUnsignedContracts"
        />
        <base-button
          v-else
          v-tooltip="!canGenerateContracts && 'Todos los integrantes deben tener KYC aprobado'"
          text="Generar contratos"
          class="w-full self-start md:w-auto"
          :disabled="!canGenerateContracts"
          :loading="isGenerateUnsignedContractsPending"
          @click="generateUnsignedContracts"
        />
      </template>
      <base-button
        v-else-if="groupLoanApplication.aasmState === 'contracts_signed'"
        text="Marcar como desembolsado"
        class="self-start"
        :loading="fireAasmStateEventMutation.isPending"
        @click="fireAasmStateEventMutation.mutate({ aasmStateEvent: 'disburse' })"
      />
      <base-button
        v-if="'application_rejected' === groupLoanApplication.aasmState"
        text="Revivir solicitud"
        class="self-start"
        @click="() => fireAasmStateEventMutation.mutate({ aasmStateEvent: 'restart_application' })"
      />
      <base-list-box
        v-if="currentListBoxOption"
        :key="currentListBoxOption.value"
        class="w-44 rounded-md border border-gray-200"
        :options="listBoxOptions"
        :initial-selected="currentListBoxOption"
        :loading="updateGroupLoanApplicationAasmStateMutation.isPending"
        @update:model-value="(option) => updateGroupLoanApplicationAasmStateMutation.mutate(
          { aasmState: option.value }
        )"
      />
      <base-button
        v-if="[
          'inviting',
          'draft',
          'application_in_review',
          'in_kyc',
          'kyc_in_review',
          'contracts_signed',
          'contracts_signature_pending',
          'disbursed'
        ].includes(groupLoanApplication.aasmState)"
        variant="text"
        class="md:self-start"
        @click="cancelApplicationConfirmationModalOpen = true"
      >
        Cancelar solicitud
      </base-button>
      <button
        v-if="[
          'inviting',
          'draft',
          'application_in_review',
          'in_kyc',
          'kyc_in_review'
        ].includes(groupLoanApplication.aasmState)"
        class="flex items-center justify-center rounded-lg bg-red-500 p-2"
        @click="rejectApplicationConfirmationModalOpen = true"
      >
        <TrashIcon
          class="size-4 shrink-0 stroke-white"
        />
      </button>
    </div>
    <group-loan-application-header-tabs
      :group-connections="groupConnections"
      :group-loan-application="groupLoanApplication"
      :promoter-president-invite="promoterPresidentInvite"
      :consolidated-loans="consolidatedLoans"
      :composition-changes="compositionChanges"
    />
  </div>
  <base-modal
    :show="passApplicationToReviewConfirmationModalOpen"
    title="¿Estás seguro?"
    accept-button-text="Sí, pasar a revisión"
    cancel-button-text="Cancelar"
    :body="group.pendingInvitations.length > 0
      ? `Hay ${group.pendingInvitations.length} invitaciones pendientes. ¿Estás seguro que deseas pasar a revisión?`
      : undefined"
    @accept="passApplicationToReview"
    @close="passApplicationToReviewConfirmationModalOpen = false"
  />
  <base-modal
    :show="passApplicationToKycInReviewConfirmationModalOpen"
    title="¿Estás seguro?"
    body="Los integrantes que no hayan subido sus documentos serán desactivados."
    accept-button-text="Sí, pasar KYC a revisión"
    cancel-button-text="Cancelar"
    @accept="passApplicationToKycInReview"
    @close="passApplicationToKycInReviewConfirmationModalOpen = false"
  />
  <group-loan-application-rejection-modal
    :rejection-tags="rejectionTags"
    :show="rejectApplicationConfirmationModalOpen"
    @submit="rejectApplication"
    @close="rejectApplicationConfirmationModalOpen = false"
  />
  <group-loan-application-cancellation-modal
    :show="cancelApplicationConfirmationModalOpen"
    @submit="cancelApplication"
    @close="cancelApplicationConfirmationModalOpen = false"
  />
</template>
